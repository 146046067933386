import axios from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/overworkforms', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        previewItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/overworkforms/view/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/overworkforms/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/overworkforms', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/overworkforms', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/overworkforms/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        cancelItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/overworkforms/cancel/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        durationCalculate(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/overworkforms/duration-calculate', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        changeEndDate(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/overworkforms/change-end-date', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        bulkItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/overworkforms/bulk', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        export(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/overworkforms/export', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        childEmployees(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/useremployees/child-employees', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
