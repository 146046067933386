<template>
  <div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="pb-1">
          <b-col cols="12" md="7" lg="7">
            <b-card no-body class="p-1 mb-0">
              <div class="d-flex justify-content-between align-items-start">
                <div class="d-flex">
                  <feather-icon icon="CodesandboxIcon" size="19"/>
                  <h5 class="mb-0 ml-50">Fazla Mesai Formu Bilgileri</h5>
                </div>
                <b-button-toolbar justify>
                  <b-button-group>
                    <b-button variant="primary" type="submit" size="sm" v-if="editForm">
                      <span class="align-middle" role="button"> Kaydet</span>
                    </b-button>
                    <b-button :to="{ name: 'form-overwork-list' }" variant="outline-secondary" size="sm">
                      <span class="align-middle" role="button">Vazgeç</span>
                    </b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
              <b-row class="mt-1 mb-0">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Fazla Mesai Nedeni">
                    <b-form-group label="* Fazla Mesai Nedeni" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.overWorkFormReasonTypeId" :options="overWorkFormReasonTypeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Vardiya">
                    <b-form-group label="Vardiya" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.shiftTypeId" :options="shiftTypeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="d-flex">
                <feather-icon icon="CalendarIcon" size="16"/>
                <h5 class="mb-0 ml-50">Mesai Tarihleri</h5>
              </div>
              <b-row class="mt-1 mb-1">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Başlangıç Zamanı" rules="required">
                    <b-form-group label="* Başlangıç Zamanı" :state="getValidationState(validationContext)">
                      <flatpickr v-model="startDate" class="form-control" :config="{enableTime: true, defaultHour: 8, defaultMinute: 0, minuteIncrement: 30, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1, minDate: minStartDate}"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Toplam Süre (Saat)" rules="required">
                    <b-form-group label="* Toplam Süre (Saat)" :state="getValidationState(validationContext)">
                      <b-form-input trim placeholder="Toplam Süre (Saat)" v-model="durationInHours"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Bitiş Zamanı">
                    <b-form-group label="Bitiş Zamanı" :state="getValidationState(validationContext)">
                      <flatpickr disabled v-model="dataInfo.endDate" class="form-control" :config="{enableTime: true, defaultHour: 18, defaultMinute: 0, minuteIncrement:30, altInput: true, altFormat: 'd.m.Y H:i', dateFormat: 'Y-m-d H:i', time_24hr: true, wrap: true, locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <div class="d-flex">
                <feather-icon icon="InfoIcon" size="16"/>
                <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
              </div>
              <b-row class="mt-1 mb-1">
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Açıklama" rules="required">
                    <b-form-group label="* Açıklama">
                      <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="2"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-col cols="12" md="12" lg="12" class="text-center p-1 pt-2">
                <b-form-checkbox class="custom-control-success mr-5" v-model="checkChildEmployee" switch inline>Bağlı Çalışanlarım Adına Form Dolduracağım</b-form-checkbox>
              </b-col>
            </b-card>
          </b-col>
          <b-col cols="12" md="5" lg="5" v-if="checkChildEmployee">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-card no-body class="h-100 p-1 mb-0 scroll-flow">
                  <div class="d-flex">
                    <feather-icon icon="UsersIcon" size="16"/>
                    <h5 class="mb-0 ml-50">Fazla Mesaiye Katılacaklar</h5>
                  </div>
                  <div class="d-flex justify-content-between mb-1 mt-1 mr-5">
                    <v-select v-model="newEmployeeUserId" :clearable="false" :options="childEmployeeList" label="fullName" :reduce="val => val.id" class="select-size-sm">
                      <template #option="{ profilePicture, fullName }">
                        <b-avatar size="sm" :src="getApiFile(profilePicture)" :text="(fullName != null && !busy ? avatarText(fullName) : '')"/>
                        <span class="ml-50 align-middle"> {{ fullName }}</span>
                      </template>
                      <template #selected-option="{ profilePicture, fullName }">
                        <b-avatar size="sm" class="border border-white" :src="getApiFile(profilePicture)" :text="(fullName != null && !busy ? avatarText(fullName) : '')"/>
                        <span class="ml-50 align-middle"> {{ fullName }}</span>
                      </template>
                    </v-select>
                    <b-button variant="primary" size="sm" class="ml-1" @click="addEmployeeUser()" v-if="editForm">
                      <feather-icon icon="PlusSquareIcon" size="16"/>
                    </b-button>
                  </div>
                  <b-list-group v-if="userEmployees.length > 0">
                    <b-list-group-item v-for="(user, index) in userEmployees" :key="index">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                          <b-avatar class="mr-1" :src="getApiFile(user.profilePicture)" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                          <div class="profile-user-info">
                            <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                            <div class="d-flex justify-content-start align-items-start flex-column">
                              <small class="text-muted">{{ user.positionName }}</small>
                            </div>
                          </div>
                        </div>
                        <div>
                          <b-button variant="gradient-danger" class="btn-icon" size="sm" @click="removeEmployeeUser(user)" v-if="editForm">X</b-button>
                        </div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <div class="empty-card border h-100 py-1" v-if="userEmployees.length === 0">
                    <b-media no-body>
                      <b-media-aside class="mb-1">
                        <b-avatar size="70" variant="light-warning">
                          <feather-icon size="30" icon="UsersIcon"/>
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                      </b-media-body>
                    </b-media>
                    <b-card-text class="font-small-3 mb-0">
                      Henüz çalışan eklenmemiş...
                    </b-card-text>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </div>
</template>

<script>
import {
  BCard, VBPopover, VBTooltip, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BMedia, BMediaAside, BMediaBody, BFormFile, BAlert, BTable, BButton, BListGroup, BBadge, BCardText, BListGroupItem, BButtonToolbar, BButtonGroup
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {computed, onUnmounted, ref, watch} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@core/utils/validations/validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/form/overwork/store"
import flatpickr from "vue-flatpickr-component/src/component"
import definitionModule from "@/views/system/definition/store";
import Vue from "vue";

export default {
  components: {
    BButtonGroup, BButtonToolbar,
    BListGroupItem, BCardText, BBadge, BListGroup,
    BButton,
    BTable, BAlert,
    BFormFile,
    BMediaBody,
    BMediaAside,
    BMedia,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,
    VBPopover,
    VBTooltip,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  setup() {

    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)
    const editForm = ref(true)

    const dataInfo = ref({
      id: 0,
      userId: JSON.parse(localStorage.getItem('userData')).id,
      overWorkFormReasonTypeId: null,
      startDate: null,
      endDate: null,
      durationInHours: null,
      shiftTypeId: null,
      description: '',
      bypassWorkflowCheck: false,
      users: []
    })

    const startDate = ref(null)
    const durationInHours = ref(null)
    const checkChildEmployee = ref(false)
    const newEmployeeUserId = ref(null)

    watch([startDate, durationInHours], () => {
      if (startDate.value !== null && durationInHours.value !== null) {
        busy.value = true
        store.dispatch('store/durationCalculate', {startDate: startDate.value, durationInHours: durationInHours.value, userId: dataInfo.value.userId}).then(response => {
          dataInfo.value.endDate = response.data.data.endDate
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const overWorkFormReasonTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'OVERWORK_FORM_REASON_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        overWorkFormReasonTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.overWorkFormReasonTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const shiftTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'SHIFT_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        shiftTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.shiftTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      dataInfo.value.startDate = startDate.value
      dataInfo.value.durationInHours = durationInHours.value

      if (checkChildEmployee.value === false) {
        dataInfo.value.users = []
      }

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'form-overwork-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        startDate.value = dataInfo.value.startDate
        durationInHours.value = dataInfo.value.durationInHours

        if (dataInfo.value.users.length > 0) {
          checkChildEmployee.value = true
        }

        if (dataInfo.value.userId.toString() !== JSON.parse(localStorage.getItem('userData')).id.toString()) {
          editForm.value = false
        }

      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'form-overwork-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {refFormObserver, getValidationState, resetForm} = formValidation()

    const pastDay = JSON.parse(localStorage.getItem('employeeData')).settings.filter(function (creature) {
      return creature.key === "PastDay";
    })[0].value;

    const minStartDate = new Date();
    minStartDate.setDate(minStartDate.getDate() - parseInt(pastDay));
    minStartDate.setHours(18, 0, 0)

    const childEmployeeList = ref([])
    busy.value = true
    store.dispatch('store/childEmployees', {addMeList: true}).then(response => {
      childEmployeeList.value = response.data.data
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const addEmployeeUser = () => {
      if (newEmployeeUserId.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Lütfen seçim işlemini yapınız!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (dataInfo.value.users.filter(item => item.userId === newEmployeeUserId.value && item.isDeleted === false).length === 0) {
        const selectedUser = childEmployeeList.value.filter(item => item.id === newEmployeeUserId.value)[0]

        dataInfo.value.users.push({
          userId: selectedUser.id,
          id: null,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          fullName: selectedUser.firstName + ' ' + selectedUser.lastName,
          positionName: selectedUser.positionName,
          profilePicture: selectedUser.profilePicture,
          index: Math.floor(Math.random() * 112114115171),
          isDeleted: false,
        })

        newEmployeeUserId.value = null

      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kayıt daha önce eklenmiş!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    const removeEmployeeUser = (userData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.users.forEach((value, index) => {
            if (value === userData) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const userEmployees = computed(() => dataInfo.value.users.filter(item => item.isDeleted === false));

    return {
      busy,
      dataInfo,
      refFormObserver,
      startDate,
      durationInHours,
      minStartDate,
      overWorkFormReasonTypeOptions,
      shiftTypeOptions,
      checkChildEmployee,
      childEmployeeList,
      newEmployeeUserId,
      userEmployees,
      editForm,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      addEmployeeUser,
      removeEmployeeUser,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.select-size-sm {
  min-width: 100%;
}

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

</style>